<template>
  <div class="season-detail">
    <div class="season-info">
      <span class="h1">{{ leagueId }}</span> <span>{{ info.sportName }}</span
      >| <span>{{ info.regionName }}</span
      >|
      <span class="h1">{{ info.tournament }} {{info.seasonName}}</span>
    </div>
    <div class="btns">
      <el-button type="" v-if="currentTab === '3'" size="mini" @click="addFn(5)"
        >Add</el-button
      >
      <el-button type="" v-if="currentTab === '2'" size="mini" @click="autoAddTeam"
        >Auto Add</el-button
      >
      <el-button type="" v-if="currentTab === '2'" size="mini" @click="addFn(3)"
        >Add Team</el-button
      >
    </div>
    <el-tabs v-model="currentTab" type="card" @tab-click="getList">
      <el-tab-pane label="Matches" name="1">
        <div class="wrap">
          <div class="header">
            <div class="search">
              <span v-if="isCup">
                Phase
                <select-box
                  @change="getList"
                  :value.sync="queryData.phase"
                  :url="TournamentPhaseList"
                  value-key="phaseCode"
                  label-key="phaseName"
                  search-key="phaseName"
                  :queryOrderParams="{ page: 1, size: 100, flag: false }"
                />
              </span>
              <span v-else>
                Round
                <el-input-number
                  @change="getList"
                  :min="1"
                  v-model="queryData.round"
                  step-strictly
                  :controls="false"
                  placeholder="Input"
                ></el-input-number>
              </span>
            </div>
            <div class="btns">
              <el-button type="" size="mini" @click="addFn(4)">Auto Add</el-button>
              <el-button type="" size="mini" @click="addFn(1)"
                >Add Match</el-button
              >
            </div>
          </div>
          <div class="main">
            <el-table :data="matchList" border="" size="mini">
              <el-table-column
                label="Number"
                sortable=""
                prop="num"
              ></el-table-column>
              <el-table-column
                label="Match Id"
                prop="matchId"
              ></el-table-column>
              <el-table-column
                label="Running Time"
                sortable=""
                prop="runningTime"
              >
                <template v-slot="{ row }">
                  {{ row.runningTime | formatTime }}
                </template>
              </el-table-column>
              <el-table-column label="Match" align="center" min-width="260">
                <template v-slot="{ row }">
                  <div class="match">
                    <span>{{ row.home }}</span>
                    <span class="score">
                      <el-tooltip content="" placement="top"  v-if="row.score">
                        <span style="color: #01a8f0;">
                         <div v-if="+info.sportId === 1">{{row.score['1000:1001']}} : {{row.score['1000:1002']}}</div> 
                         <div v-if="+info.sportId === 3">{{row.score['3001:3001']}} : {{row.score['3001:3002']}}</div> 
                        </span>
                        <template #content>
                          <div v-if="+info.sportId === 1">
                          <div v-if="row.score['1001:1001'] !== undefined">FT: {{row.score['1001:1001']}} : {{row.score['1001:1002']}}</div>
                          <div v-if="row.score['1013:1001'] !== undefined">ET: {{row.score['1013:1001']}} : {{row.score['1013:1002']}}</div>
                          <div v-if="row.score['1006:1001'] !== undefined">PEN: {{row.score['1006:1001']}} : {{row.score['1006:1002']}}</div>
                          </div>
                          <div v-if="+info.sportId === 3">
                          <div v-if="row.score['3005:3001'] !== undefined">Q1: {{row.score['3005:3001']}} : {{row.score['3005:3002']}}</div>
                          <div v-if="row.score['3006:3001'] !== undefined">Q2: {{row.score['3006:3001']}} : {{row.score['3006:3002']}}</div>
                          <div v-if="row.score['3007:3001'] !== undefined">Q3: {{row.score['3007:3001']}} : {{row.score['3007:3002']}}</div>
                          <div v-if="row.score['3008:3001'] !== undefined">Q4: {{row.score['3008:3001']}} : {{row.score['3008:3002']}}</div>
                          <div v-if="row.score['3009:3001'] !== undefined">PEN: {{row.score['3009:3001']}} : {{row.score['3009:3002']}}</div>
                          </div>
                        </template>
                      </el-tooltip>
                      <span v-else>-</span>
                    </span>
                    <span>{{ row.away }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :label="!isCup ? 'Round' : 'Phase'"
                :prop="!isCup ? 'round' : 'phaseName'"
                sortable=""
              >
                <template v-slot="{ row }">
                  {{ !isCup ? row.round : row.phaseName }}
                </template>
              </el-table-column>
              <el-table-column label="Operation">
                <template v-slot="{ row }">
                  <div class="operation-btns">
                    <el-tooltip content="Edit" placement="top">
                      <span
                        class="el-icon-edit-outline"
                        @click="matchEdit(row)"
                      ></span>
                    </el-tooltip>
                    <el-tooltip content="Delete" placement="top">
                      <span
                        class="el-icon-delete"
                        @click="matchDelete(row)"
                      ></span>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Teams" name="2">
        <div class="wrap">
          <div class="group" v-for="(item, key) in teamList" :key="key">
            <div class="header" style="margin-top: 10px" v-if="isCup">
              <span
                >{{ item[0].groupName }} <span class="el-icon-delete" @click="groupDel(key)"></span
              ></span>
            </div>
            <div class="main">
              <el-table :data="item" border="" size="mini" :default-sort="{prop: 'rank'}">
                <el-table-column
                  label="Rank"
                  prop="rank"
                  sortable=""
                >
                <template v-slot="{row, column}">
                  <el-input-number size="mini" @change="updateTeam(row, column)" v-model="row.rank" placeholder="Input" :controls="false" :min="0" step-strictly></el-input-number>
                </template>
                </el-table-column>
                <el-table-column
                  label="Team Id"
                  prop="teamId"
                ></el-table-column>
                <el-table-column label="Name" prop="name"></el-table-column>
                <el-table-column
                  label="Played"
                  prop="played"
                  sortable=""
                >
                 <template v-slot="{row, column}">
                  <el-input-number size="mini" @change="updateTeam(row, column)" v-model="row.played" placeholder="Input" :controls="false" :min="0" step-strictly></el-input-number>
                </template>
                </el-table-column>
                <el-table-column
                  label="Win"
                  prop="win"
                  sortable=""
                  class-name="win"
                >
                 <template v-slot="{row, column}">
                  <el-input-number size="mini" @change="updateTeam(row, column)" v-model="row.win" placeholder="Input" :controls="false" :min="0" step-strictly></el-input-number>
                </template>
                </el-table-column>
                <el-table-column
                  label="Draw"
                  prop="draw"
                  sortable=""
                  class-name="draw"
                >
                 <template v-slot="{row, column}">
                  <el-input-number size="mini" @change="updateTeam(row, column)" v-model="row.draw" placeholder="Input" :controls="false" :min="0" step-strictly></el-input-number>
                </template>
                </el-table-column>
                <el-table-column
                  label="Lose"
                  prop="lose"
                  class-name="lose"
                  sortable=""
                >
                 <template v-slot="{row, column}">
                  <el-input-number size="mini" @change="updateTeam(row, column)" v-model="row.lose" placeholder="Input" :controls="false" :min="0" step-strictly></el-input-number>
                </template>
                </el-table-column>
                <el-table-column
                  label="Goals For"
                  prop="forGoals"
                  sortable=""
                >
                 <template v-slot="{row, column}">
                  <el-input-number size="mini" @change="updateTeam(row, column)" v-model="row.forGoals" placeholder="Input" :controls="false" :min="0" step-strictly></el-input-number>
                </template>
                </el-table-column>
                <el-table-column
                  label="Goals Against"
                  prop="againstGoals"
                  sortable=""
                >
                 <template v-slot="{row, column}">
                  <el-input-number size="mini" @change="updateTeam(row, column)" v-model="row.againstGoals" placeholder="Input" :controls="false" :min="0" step-strictly></el-input-number>
                </template>
                </el-table-column>
                <el-table-column
                  label="Goals Different"
                  prop="diffGoals"
                  sortable=""
                >
                </el-table-column>
                <el-table-column
                  label="Points"
                  prop="points"
                  sortable=""
                >
                 <template v-slot="{row, column}">
                  <el-input-number size="mini" @change="updateTeam(row, column)" v-model="row.points" placeholder="Input" :controls="false" :min="0" step-strictly></el-input-number>
                </template>
                </el-table-column>
                <el-table-column
                  label="Ranking of third-placed"
                  prop="thirdPlacedRanking"
                  sortable=""
                >
                 <template v-slot="{row, column}">
                  <el-input-number size="mini" @change="updateTeam(row, column)" v-model="row.thirdPlacedRanking" placeholder="Input" :controls="false" :min="0" step-strictly></el-input-number>
                </template>
                </el-table-column>
                <el-table-column label="Operation" prop="Operation">
                  <template v-slot="{ row }">
                    <div class="operation-btns">
                      <el-tooltip content="Delete" placement="top">
                        <span
                          class="el-icon-delete"
                          @click="teamDelete(row)"
                        ></span>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Players" name="3">
        <div class="wrap">
        <el-table :data="playerList" border="" height="100%">
          <el-table-column label="Player Id" prop="playerId"></el-table-column>
          <el-table-column label="Name" prop="name"></el-table-column>
          <el-table-column label="Team" prop="teamId">
            <template v-slot="{row, column}">
               <select-box
               :remote="false"
               @change="updatePlayer(row, column)"
              :url="STD_SEARCH_TEAM_PLAYER"
              :queryOrderParams="{
                page: 1,
                size: 100,
                isVisible: 1,
                leagueId: leagueId
              }"
              :value.sync="row.teamId"
              :label="row.teamName"
              search-key="name"
              label-key="name"
              value-key="teamId"
            />
            </template>
          </el-table-column>
          <el-table-column label="Goals" prop="goals" sortable="">
             <template v-slot="{row, column}">
                <el-input-number size="mini" @change="updatePlayer(row, column)" v-model="row.goals" placeholder="Input" :controls="false" :min="0" step-strictly></el-input-number>
              </template>
          </el-table-column>
          <el-table-column label="Penalty Kicks" prop="kicks" sortable="">
             <template v-slot="{row, column}">
                <el-input-number size="mini" @change="updatePlayer(row, column)" v-model="row.kicks" placeholder="Input" :controls="false" :min="0" step-strictly></el-input-number>
              </template>
          </el-table-column>
          <el-table-column label="Assists" prop="assists" sortable="">
             <template v-slot="{row, column}">
                <el-input-number size="mini" @change="updatePlayer(row, column)" v-model="row.assists" placeholder="Input" :controls="false" :min="0" step-strictly></el-input-number>
              </template>
          </el-table-column>
          <el-table-column label="Operation" prop="Operation">
             <template v-slot="{ row }">
                    <div class="operation-btns">
                      <el-tooltip content="Delete" placement="top">
                        <span
                          class="el-icon-delete"
                          @click="playerDelete(row)"
                        ></span>
                      </el-tooltip>
                    </div>
                  </template>
          </el-table-column>
        </el-table>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      :title="currentDiagName"
      width="500px"
      :visible.sync="dialog.show"
      :close-on-click-modal="dialog.clickModal"
      :destroy-on-close="true"
    >
      <el-form
        :model="dialogForm"
        label-width="130px"
        size="mini"
        :ref="dialog.ref"
      >
        <div class="add-match" v-if="currentTab === '1'">
          <div class="auto" v-if="dialogType === 4">
            <el-form-item label="Time Range">
              <el-date-picker style="width: 320px" v-model="dialogForm.timeRange" placeholder="Select" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" type="datetimerange"></el-date-picker>
            </el-form-item>
          </div>
          <div v-else>
          <el-form-item label="Number:">
            <el-input v-model="dialogForm.num" placeholder="Input" type="number"></el-input>
          </el-form-item>
          <el-form-item label="Match Id:">
            <el-input
              @change="inputMatch"
              v-model="dialogForm.matchId"
              placeholder="Input"
            ></el-input>
          </el-form-item>
          <el-form-item label="Running Time:">
            <el-date-picker
              :disabled="!!dialogForm.matchId"
              v-model="dialogForm.runningTime"
              placeholder="Select Time"
              type="datetime"
              value-format="timestamp"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="Home:">
            <div class="team">
              <span>ID</span>
              <el-input
                :disabled="!!dialogForm.matchId"
                v-model="dialogForm.home"
                @change="inputTeam($event, 1)"
                style="width: 130px"
                placeholder="Input"
              ></el-input>
              <span>Name</span>
              <select-box
                :disabled="!!dialogForm.matchId"
                :url="STD_SEARCH_TEAM"
                ref="teamH"
                :queryOrderParams="{
                  page: 1,
                  size: 100,
                  isVisible: 1
                }"
                :value.sync="dialogForm.home"
                :label="dialogForm.homeName"
                search-key="name"
                label-key="name"
                value-key="id"
              />
            </div>
          </el-form-item>
          <el-form-item label="Away:">
            <div class="team">
              <span>ID</span>
              <el-input
                :disabled="!!dialogForm.matchId"
                v-model="dialogForm.away"
                @change="inputTeam($event, 0)"
                style="width: 130px"
                placeholder="Input"
              ></el-input>
              <span>Name</span>
              <select-box
                :disabled="!!dialogForm.matchId"
                :url="STD_SEARCH_TEAM"
                ref="teamA"
                :queryOrderParams="{
                  page: 1,
                  size: 100,
                  isVisible: 1
                }"
                :value.sync="dialogForm.away"
                :label="dialogForm.awayName"
                search-key="name"
                label-key="name"
                value-key="id"
              />
            </div>
          </el-form-item>
          <el-form-item label="Phase:" v-if="isCup" required="">
            <select-box
              :remote="false"
              :value.sync="dialogForm.phase"
              :label="dialogForm.phaseName"
              :url="TournamentPhaseList"
              value-key="phaseCode"
              label-key="phaseName"
              search-key="phaseName"
              :queryOrderParams="{ page: 1, size: 100, flag: false }"
            />
          </el-form-item>
          <el-form-item label="Round:" v-if="!isCup" required="">
            <el-input-number
              v-model="dialogForm.round"
              placeholder="Input"
              :controls="false"
              :min="1"
              :step="1"
              step-strictly
            ></el-input-number>
          </el-form-item>
          </div>
        </div>
        <div class="add-team" v-if="currentTab === '2'">
          <el-form-item label="Group" v-if="isCup" required="">
            <select-box
              :remote="false"
              :value.sync="dialogForm.groupId"
              :label="dialogForm.phaseName"
              :url="TournamentPhaseList"
              value-key="phaseCode"
              label-key="phaseName"
              search-key="phaseName"
              :queryOrderParams="{ page: 1, size: 100, flag: true }"
            />
          </el-form-item>
          <el-form-item label="Team Id:">
            <el-input
              style="width: 240px"
              v-model="dialogForm.teamId"
              @change="inputTeam($event, 2)"
              placeholder="Input"
            ></el-input>
          </el-form-item>
          <el-form-item label="Sport:" prop="sportId">
            <select-box
              :url="STD_SEARCH_SPORT"
              :queryOrderParams="{ page: 1, size: 500 }"
              :value.sync="dialogForm.sportId"
              :label="dialogForm.sportsName"
              search-key="name"
              label-key="sportsName"
              value-key="id"
            />
          </el-form-item>
          <el-form-item label="Team:" required="">
            <select-box
              :url="STD_SEARCH_TEAM"
              ref="teamSelect"
              :queryOrderParams="{
                page: 1,
                size: 100,
                isVisible: 1
              }"
              :value.sync="dialogForm.teamId"
              :label="dialogForm.teamName"
              search-key="name"
              label-key="name"
              value-key="id"
            />
          </el-form-item>
        </div>
        <div class="add-player"  v-if="currentTab === '3'">
          <el-form-item label="Player Id:">
            <el-input
              style="width: 240px"
              v-model="dialogForm.playerId"
              @change="inputPlayer($event)"
              placeholder="Input"
              clearable=""
            ></el-input>
          </el-form-item>
           <el-form-item label="Sport:" prop="sportId">
            <select-box
              :url="STD_SEARCH_SPORT"
              :queryOrderParams="{ page: 1, size: 500 }"
              :value.sync="dialogForm.sportId"
              :label="dialogForm.sportsName"
              :disabled="!!dialogForm.playerId && playerFlag"
              search-key="name"
              label-key="sportsName"
              value-key="id"
            />
          </el-form-item>
          <el-form-item label="Tournament:" prop="tournamentId">
          <select-box
            :value.sync="dialogForm.tournamentId"
            :label="dialogForm.tournamentName"
            :url="STD_SEARCH_TOURNAMENT"
            :disabled="!!dialogForm.playerId && playerFlag"
            value-key="id"
            label-key="name"
            search-key="name"
            :queryOrderParams="{ page: 1, size: 100, sportId: dialogForm.sportId }"
          />
        </el-form-item>
        <el-form-item label="Player:" required="">
            <select-box
              :url="STD_SEARCH_PLAYER"
              :queryOrderParams="{
                page: 1,
                size: 100,
                sportId: dialogForm.sportId,
                tournamentId: dialogForm.tournamentId
              }"
              ref="player"
              :disabled="!!dialogForm.playerId && playerFlag"
              :value.sync="dialogForm.playerId"
              :label="dialogForm.playerName"
              @change="playerFlag = false"
              search-key="name"
              label-key="name"
              value-key="id"
            />
          </el-form-item>
        <el-form-item label="Team:" required="">
            <select-box
              :remote="false"
              :url="STD_SEARCH_TEAM_PLAYER"
              :queryOrderParams="{
                page: 1,
                size: 100,
                isVisible: 1,
                leagueId: leagueId
              }"
              :value.sync="dialogForm.teamId"
              :label="dialogForm.teamName"
              search-key="name"
              label-key="name"
              value-key="teamId"
            />
          </el-form-item>
        </div>
        <el-form-item>
          <el-button
            type="primary"
            @click="dialogSubmit"
            :loading="dialog.isLoading"
            >Confirm</el-button
          >
          <el-button @click="dialogHide">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.season-detail {
  background: #fff;
  height: calc(100vh - 140px);
  padding: 20px;
  .team {
    display: flex;
    align-items: center;
    > span {
      flex-shrink: 0;
      margin: 0 5px;
    }
  }
  .season-info {
    color: #666;
    margin-bottom: 20px;
    .h1 {
      font-weight: bold;
      color: #000;
    }
    span {
      margin: 0 10px;
    }
  }
  > .btns {
    position: absolute;
    right: 50px;
    z-index: 3;
  }
  .wrap {
    padding: 5px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 240px);
     overflow-y: auto;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      flex-shrink: 0;
      .el-icon-delete{
        cursor: pointer;
        font-weight: bold;
        margin-left: 15px;
        &:hover{
          opacity: .7;
        }
      }
    }
    /deep/ .el-table .el-input-number{
        width: auto;
        min-width: 70px;
      }
    /deep/ .el-table .el-select{
        width: 100% !important;;
        min-width: 120px;
    }
    .main {
      flex: 1;
      .match {
        display: flex;
        align-items: center;
        span {
          display: inline-block;
          &:first-child {
            text-align: right;
            flex: 1;
          }
          &:last-child {
            text-align: left;
            flex: 1;
          }
          &.score {
            margin: 0 10px;
            width: 80px;
            flex-shrink: 0;
          }
        }
      }
      .operation-btns {
        span {
          font-size: 16px;
          font-weight: bold;
          display: inline-block;
          margin: 0 5px;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        }
      }
      /deep/ .win{
        border-left: 1px solid #666;
        // border-right: 1px solid #666;
       .cell{
          color: #00da00;
        }
      }
      /deep/ .draw{
        border-left: 1px solid #666;
        border-right: 1px solid #666;
       .cell{
          color: #666;
         
        }
      }
      /deep/ .lose{
        // border-left: 1px solid #666;
        border-right: 1px solid #666;
       .cell{
          color: red;
        }
      }
    }
  }
}
</style>

<script>
import select from '@/mixins/standard/tableSelect'
import Dialog from '@/mixins/dialog'
import {
  matchList,
  matchSave,
  matchDelete,
  teamList,
  teamSave,
  teamDelete,
  playerList,
  playerSave,
  playerDelete,
  teamBatchDelete,
  teamAutoSave,
  findTeam,
  matchAutoSave
} from '@/service/seasonManagement'
import { getMatchList, standardPlayerList } from '@/service/standard'
const dialogType = {
  0: 'Dialg Title',
  1: 'Add Match',
  2: 'Edit Match',
  3: 'Add Team',
  4: 'Auto Add Match',
  5: 'Add Player'
}
export default {
  data() {
    return {
      currentTab: '1',
      dialogType: '',
      info: {},
      playerFlag: true,
      matchList: [],
      teamList: [],
      playerList: []
    }
  },
  mixins: [select, Dialog],
  computed: {
    isCup() {
      return +this.info.tournamentType === 1
    },
    leagueId() {
      return this.info.id
    },
    currentDiagName() {
      return dialogType[this.dialogType]
    }
  },
  created() {
    this.queryData.size = 500
    try {
      this.info = JSON.parse(this.$route.query.info || '{}')
    } catch (e) {
      console.log('season info error')
    }
      // getPeriodList({sportId: 1})
  },
  methods: {
    inputPlayer(e) {
      if(!e) {
        return
      }
      this.playerFlag = true
      standardPlayerList({id: e}).then(res => {
        if(res.code === 0) {
          let list  = res.data.records || []
          if(list.length === 0) {
            this.$message.error('Not found player')
            list = [{}]
          } 
          const [{sportId, tournamentId,standardSportName,standardTournamentName, name}] = list
          this.$set(this.dialogForm, 'sportId', sportId)
          this.$set(this.dialogForm, 'tournamentId', tournamentId)
          this.$set(this.dialogForm, 'sportsName', standardSportName)
          this.$set(this.dialogForm, 'tournamentName', standardTournamentName)
          this.$set(this.dialogForm, 'playerName', name)
          setTimeout(e => {
            this.$refs.player.fixLabel()
          })
        }
      })
    },
    addFn(type) {
       this.dialogType = type
      this.dialogAdd()
    },
    autoAddTeam() {
      teamAutoSave({leagueId: this.leagueId}).then(res => {
        if(res.code === 0) {
          this.$message.success('Success')
          this.getList()
        }
      })
    },
    groupDel(id) {
       const that = this
      this.$confirm('Delete this group?', '', {
        callback(e) {
          if (e === 'confirm') {
            teamBatchDelete({ groupId: +id , leagueId: that.leagueId}).then((res) => {
              that.$message.success('Success')
              that.getList()
            })
          }
        }
      })
    },
    updateTeam(row, col) {
      const type = col.property
      teamSave({id: row.id, [type]: Math.round(row[type]) }).then(res => {
        if(res.code === 0) {
          this.$message.success('Success')
        }
      }).finally(e => {
        this.getList()
      })
    },
    updatePlayer(row, col) {
      const type = col.property
      playerSave({id: row.id, [type]: Math.round(row[type])}).then(res => {
        if(res.code === 0) {
          this.$message.success('Success')
        }
      }).finally(e => {
        this.getList()
      })
    },
    teamDelete(row) {
      const that = this
      this.$confirm('Delete this team?', '', {
        callback(e) {
          if (e === 'confirm') {
            teamDelete({ id: row.id }).then((res) => {
              that.$message.success('Success')
              that.getList()
            })
          }
        }
      })
    },
    playerDelete(row) {
      const that = this
      this.$confirm('Delete this player?', '', {
        callback(e) {
          if (e === 'confirm') {
            playerDelete({ id: row.id }).then((res) => {
              that.$message.success('Success')
              that.getList()
            })
          }
        }
      })
    },
    inputMatch(e) {
      getMatchList({
        matchId: e
      }).then((res) => {
        if (res.code === 0 && res.data) {
          const [{ beginTime, awayTeamId, homeTeamId }] = res.data.records || [
            {}
          ]
          this.$set(this.dialogForm, 'runningTime', beginTime)
          this.$set(this.dialogForm, 'home', homeTeamId)
          this.$set(this.dialogForm, 'away', awayTeamId)
          this.inputTeam(homeTeamId, 1)
          this.inputTeam(awayTeamId, 0)
        }
      })
    },
    matchEdit(row) {
      this.dialogType = 2
      const { teamIds, home: homeName, away: awayName } = row
      const [home, away] = teamIds.split(',')
      this.dialogEdit({ ...row, homeName, awayName, home: +home, away: +away })
    },
    matchDelete(row) {
      const that = this
      this.$confirm('Delete this match?', '', {
        callback(e) {
          if (e === 'confirm') {
            matchDelete({ id: row.id }).then((res) => {
              that.$message.success('Success')
              that.getList()
            })
          }
        }
      })
    },
    dialogSendData() {
      const type = this.dialogType
      console.log(type)
      if (type === 1 || type === 2) {
        const { home, away, phase, round } = this.dialogForm
        const teamIds = [home, away] + ''
        if ((!phase && this.isCup) || (!this.isCup && !round)) {
          this.dialogCb()
          return this.$message.error('Please check form info')
        }
        if(round) {
          this.dialogForm.round = Math.round(+round)
        }
        matchSave({ ...this.dialogForm, leagueId: this.leagueId, teamIds })
          .then((res) => {
            this.dialogCb(res)
          })
          .finally(() => {
            this.dialogCb()
          })
      }
      if (type === 3) {
        const {groupId, teamId} = this.dialogForm
        if ((this.isCup && !groupId) || !teamId) {
          this.dialogCb()
          return this.$message.error('Please check form info')
        }
        if(!this.isCup) {
          this.dialogForm.groupId = 0
        }
        teamSave({ ...this.dialogForm, leagueId: this.leagueId })
          .then((res) => {
            this.dialogCb(res)
          })
          .finally(() => {
            this.dialogCb()
          })
      }
      if(type === 4) {
        const [startTime, endTime] = this.dialogForm.timeRange || []
        if(!startTime) {
          this.dialogCb()
          return this.$message.error('Please select time range')
        }
        matchAutoSave({startTime, endTime, leagueId: this.leagueId}).then((res) => {
            this.dialogCb(res)
          })
          .finally(() => {
            this.dialogCb()
          })
      }
      if(type === 5) {
         const {playerId, teamId} = this.dialogForm
        if (!playerId || !teamId) {
          this.dialogCb()
          return this.$message.error('Please check form info')
        }
        playerSave({ ...this.dialogForm, leagueId: this.leagueId }).then((res) => {
            this.dialogCb(res)
          })
          .finally(() => {
            this.dialogCb()
          })
      }
    },
    dialogCb(res = {}) {
      if (res.code === 0) {
        this.$message.success('Success')
        this.dialogHide()
        this.getList()
      }
      this.dialog.isLoading = false
    },
    inputTeam(e, type = 1) {
      if(!e) {
        return
      }
      const refName = type === 1 ? 'teamH' : type == 0 ? 'teamA' : 'teamSelect'
      findTeam({ id: e }).then((res) => {
        if (res.code === 0) {
          const list = res.data.records
          const [{ name }] = list
          this.$refs[refName].optionData = list
          this.$nextTick((e) => {
            console.log(name)
            this.$refs[refName].$refs.select.selectedLabel = name
          })
        }
      })
    },
    getList() {
      if (this.currentTab === '1') {
        matchList({ ...this.queryData, leagueId: this.leagueId }).then(
          (res) => {
            const { code, data } = res
            if (code === 0) {
              this.matchList = (data.records || []).map(item => {
                item.num = +item.num
                return item
              })
            }
          }
        )
      }
      if (this.currentTab === '2') {
        teamList({ ...this.queryData, leagueId: this.leagueId }).then((res) => {
          const { code, data } = res
          if (code === 0) {
            const list = data.records || []
            const teamData = {}
            list.map((item) => {
              const gid = item.groupId
              if (gid in teamData) {
                teamData[gid].push(item)
              } else {
                teamData[gid] = [item]
              }
            })
            this.teamList = teamData
          }
        })
      }
      if (this.currentTab === '3') {
        playerList({ ...this.queryData, leagueId: this.leagueId }).then(
          (res) => {
            const { code, data } = res
            if (code === 0) {
              this.playerList = data.records || []
            }
          }
        )
      }
    }
  }
}
</script>