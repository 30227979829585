const Sports = () => import('./sports')
const Region = () => import('./region')
const Team = () => import('./team')
const tournament = () => import('./tournament')
const Event = () => import('./event')
const EventType = () => import('./eventType')
const MarketType = () => import('./marketType')
const Period = () => import('./period')
const Option = () => import('./option')
const MatchPeriod = () => import('./matchPeriod')
const MatchStatus = () => import('./matchStatus')
const TournamentPhase = () => import('./tournamentPhase')
const Player = () => import('./player')
const OutrightMatch = () => import('./outrightMatch');
const sportRules = () => import('./sportRules')
export {
  Sports,
  Region,
  Team,
  tournament,
  Event,
  EventType,
  MarketType,
  Period,
  Option,
  MatchPeriod,
  MatchStatus,
  TournamentPhase,
  Player,
  OutrightMatch,
  sportRules
}
