import { post } from '@/utils/request'

export const tournamentPhaseList = (data) => {
  return post('enum/tournamentPhaseList', data, 'json')
}
export const seasonList = (data) => {
  return post('special/season/list', data, 'json')
}
export const leagueList = (data) => {
  return post('special/league/list', data, 'json')
}
export const leagueSave = (data) => {
  return post('special/league/save', data, 'json')
}
export const leagueDelete = (data) => {
  return post('special/league/delete', data, 'formData')
}
export const matchList = (data) => {
  return post('special/match/list', data, 'json')
}
export const matchSave = (data) => {
  return post('special/match/save', data, 'json')
}
export const matchDelete = (data) => {
  return post('special/match/delete', data, 'formData')
}
export const teamList = (data) => {
  return post('special/team/list', data, 'json')
}
export const teamSave = (data) => {
  return post('special/team/save', data, 'json')
}
export const teamDelete = (data) => {
  return post('special/team/delete', data, 'formData')
}
export const playerList = (data) => {
  return post('special/player/list', data, 'json')
}
export const playerSave = (data) => {
  return post('special/player/save', data, 'json')
}
export const playerDelete = (data) => {
  return post('special/player/delete', data, 'formData')
}
export const tournament = (data) => {
  return post('tournament/list', data, 'json')
}
export const findTeam = (data) => {
  return post('team/list', data, 'json')
}
export const matchAutoSave = (data) => {
  return post('special/match/autoSave', data, 'json')
}
export const teamAutoSave = (data) => {
  return post('special/team/autoSave', data, 'json')
}
export const teamBatchDelete = (data) => {
  return post('special/team/batchDelete', data, 'formData')
}

